import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Terms() {
  return (
    <div className="container mt-5">
      <h1>Terms and Conditions</h1>
      <p>Last updated: [Date]</p>
      
      <h2>1. Introduction</h2>
      <p>
        Welcome to the Tax Calculator application. By accessing or using our application, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with any part of the Terms, you must not use our application.
      </p>

      <h2>2. Use of the Application</h2>
      <p>
        The Tax Calculator application is provided for informational purposes only. The results generated by the application are based on the information you provide and may not be accurate or complete. You should not rely on the results for making financial or tax-related decisions. Always consult with a qualified tax professional for advice tailored to your specific circumstances.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        You agree to use the application in compliance with all applicable laws and regulations. You must not use the application for any unlawful or fraudulent purposes.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the application and your use thereof. We do not warrant that the application will be error-free or uninterrupted. In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the application.
      </p>

      <h2>5. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our application. Your continued use of the application after any changes constitutes your acceptance of the new Terms.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of Hong Kong. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Hong Kong.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact us at chysamuel105@gmail.com.
      </p>
    </div>
  );
}

export default Terms;
