export const countries = [
    { value: 'Australia', label: 'Australia' },
    { value: 'Canada(federal)', label: 'Canada(federal)' },
    { value: 'China', label: 'China' },
    { value: 'France', label: 'France' },
    { value: 'Hong Kong(income under 5,000,000)', label: 'Hong Kong(income under 5,000,000)' },
    { value: 'India(without surcharge)', label: 'India(without surcharge)' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Korea', label: 'Korea' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'UAE', label: 'UAE' },
    { value: 'UK(except Scotland)', label: 'UK(except Scotland)' },
    { value: 'USA(federal)', label: 'USA(federal)' },
    // Add more countries as needed
  ];
  