import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Select from 'react-select';
import Navbar from './Navbar';
import About from './About';
import Terms from './Terms';
import { countries } from './countries';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

function Home() {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [income, setIncome] = useState('');
  const [totalTax, setTotalTax] = useState(null);
  const [web, setWeb] = useState('');
  const [date, setDate] = useState('');
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreed) {
      alert("You must agree to the terms and conditions to proceed.");
      return;
    }
    console.log('Form submitted with:', { selectedCountry, income });
    try {
      // change to this for development http://127.0.0.1:5000/calculate
      // change to this for deployment https://tax-com.vercel.app/calculate
      const response = await axios.post('https://tax-com.vercel.app/calculate', {
        country: selectedCountry.value,
        income,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response received:', response.data);
      setTotalTax(response.data.total_tax);
      setWeb(response.data.web);
      setDate(response.data.date);
    } catch (error) {
      console.error('Error calculating tax:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Income tax(Salary) Calculator</h1>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow-sm bg-light">
        <div className="mb-3">
          <label htmlFor="country" className="form-label">Country/Region</label>
          <Select
            id="country"
            options={countries}
            value={selectedCountry}
            onChange={setSelectedCountry}
            placeholder="Select a country"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="income" className="form-label">Income (Please enter the local currency according to the chosen country/region)</label>
          <input 
            type="number" 
            id="income" 
            name="income" 
            className="form-control" 
            value={income} 
            onChange={(e) => setIncome(e.target.value)} 
            required 
          />
        </div>
        <div className="mb-3 form-check">
          <input 
            type="checkbox" 
            className="form-check-input" 
            id="terms" 
            checked={agreed} 
            onChange={(e) => setAgreed(e.target.checked)} 
            required 
          />
          <label className="form-check-label" htmlFor="terms">
            I agree to the <a href="/terms" target="_blank">terms and conditions</a>
          </label>
        </div>
        <button type="submit" className="btn btn-primary w-100">Calculate</button>
      </form>
      {totalTax !== null && (
        <div className="alert alert-success mt-4">
          <h2>Total Tax: {totalTax}</h2>
          <p>This result does not include any form of reducement, deductions, rebate, credits or tax cut and is only considered single full time resident.</p>
          <p>Taxation Information was gathered from <a href={web} target="_blank" rel="noopener noreferrer">{web}</a> on {date}</p>
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
 
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
