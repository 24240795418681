import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
  return (
    <div className="container mt-5">
      <h1>About Us</h1>
      <p>
        Welcome to our Tax Calculator application. Our goal is to provide a user-friendly tool that helps people gain a basic understanding of the tax systems in different countries. Whether you're planning to move, work, or simply curious about how taxes work around the world, our application offers a straightforward way to get an overview of the tax rates and structures in various nations.
      </p>
      <p>
        We strive to make complex tax information more accessible and easier to understand. Our tool allows you to input your income and select a country to see an estimate of the taxes you might owe. Please note that the information provided is for general informational purposes only and should not be considered as professional tax advice.
      </p>
      <p>
        For more detailed and personalized tax advice, we recommend consulting with a tax professional or the relevant tax authority in your country of interest.
      </p>
      <p>
        Thank you for using our Tax Calculator. We hope it helps you in understanding the basic tax structures around the world.
      </p>
      <p>
        If you have any questions or feedback, feel free to contact us at <a href="mailto:taxcalcom@gmail.com">taxcalcom@gmail.com</a>.
      </p>
    </div>
  );
};

export default About;
